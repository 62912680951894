<template>
  <div class="me" id="me" :class="{on : pageLoaded}">
      <div class="me-content">
        <van-list v-model="loading" :finished="finished" :immediate-check="false" loading-text=" " :finished-text="list.length > 0 ? '我是有底线的' : ''"  @load="onLoadList" :offset="100">
          <!-- avatar_bg -->
            <div class="me-avatar">
                <div class="avatar">
                    <div class="me-cover"></div>
                    <van-image :src="userData.head_img || ''" :width="375/37.5+'rem'" :height="375/37.5+'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                </div>
                
            </div>

            <div class="me-info">
                <div class="top">
                    <!-- avatar -->
                    <div class="avatar">
                        <div class="me-cover"></div>
                        <van-image :src="userData.head_img || ''" :width="100/37.5+'rem'" :height="100/37.5+'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                    </div>

                    <!-- vip -->
                    <c-image iUrl="icon-vip" iClass="vip" iWidth="28" iHeight="28" v-if="isVip"></c-image>
                </div>

                <h1>{{userData.nickname}}</h1>
                <h2>{{userData.sign_name}}</h2>
                
                <!-- tag -->
                <div class="tags_m">
                    <c-image :iUrl="'icon-vip_'+userData.user_level" iClass="tag_m" iWidth="28" iHeight="24"></c-image>
                    <c-image iUrl="icon-original" iClass="tag_m" iWidth="28" iHeight="24" v-if="userData.bonus_level>100"></c-image>
                </div>

                <!-- follow -->
                <div class="btns" v-if="!isMe">
                    <div class="btn t1" v-if="!isFollow" @click="onFollow(1)">关注</div>
                    <div class="btn t3" v-else @click="onFollow(0)">已关注</div>
                </div>
            </div>

            <!-- tab -->
            <div class="me-tab">
                <van-tabs v-model="tabActive" color="#2E2E2E"  @change="onChangeTab">
                    <!-- 作品 -->
                    <van-tab title="作品" :badge="workNum">
                        <div class="me-products" :class="{on : tabLoaded}" v-if="tabActive == 0">
                            <template v-if="list.length > 0">
                                <c-waterfall :list="list" :winWidth="winWidth" @reload="onReloadProducts" @homepageStepPage="onHomepageStepPage"></c-waterfall>
                            </template>
                            <template v-else>
                                <c-image iUrl="collect-empty" iClass="empty" iWidth="120" iHeight="92" v-if="tabLoaded"></c-image>
                                <h1 class="empty_cue">TA还没有上传作品</h1>
                                <p class="empty_cue2">看看别的设计师吧</p>
                            </template>
                        </div>
                    </van-tab>
                    <!-- 关注 -->
                    <van-tab title="关注" :badge="followNum">
                        <div class="me-account" :class="{on : tabLoaded}" v-if="tabActive == 1">
                            <!-- list -->
                            <template v-if="list.length > 0">
                                <c-user-cards :list="list" cardType="follow" @homepageStepPage="onHomepageStepPage"></c-user-cards>
                            </template>
                            <template v-else>
                                <c-image iUrl="collect-empty" iClass="empty" iWidth="120" iHeight="92" v-if="tabLoaded"></c-image>
                                <h1 class="empty_cue">TA还没有关注设计师</h1>
                                <p class="empty_cue2">看看别的设计师吧</p>
                            </template>
                        </div>
                    </van-tab>
                    <!-- 发现 -->
                    <van-tab title="发现" :badge="findNum">
                        <div class="me-products" :class="{on : tabLoaded}" v-if="tabActive == 2">
                            <template v-if="list.length > 0">
                                <c-waterfall :list="list" :winWidth="winWidth" @reload="onReloadProducts" @homepageStepPage="onHomepageStepPage"></c-waterfall>
                            </template>
                            <template v-else>
                                <c-image iUrl="collect-empty" iClass="empty" iWidth="120" iHeight="92" v-if="tabLoaded"></c-image>
                                <h1 class="empty_cue">TA关注的设计师还没有新作品</h1>
                                <p class="empty_cue2">看看别的设计师吧</p>
                            </template>
                        </div>
                    </van-tab>
                    <!-- 粉丝 -->
                    <van-tab title="粉丝" :badge="fansNum">
                        <div class="me-account" :class="{on : tabLoaded}" v-if="tabActive == 3">
                            <!-- list -->
                           <template v-if="list.length > 0">
                               <c-user-cards :list="list" cardType="fans" @homepageStepPage="onHomepageStepPage"></c-user-cards>
                            </template>
                            <template v-else>
                                <c-image iUrl="collect-empty" iClass="empty" iWidth="120" iHeight="92" v-if="tabLoaded"></c-image>
                                 <h1 class="empty_cue">TA还没有粉丝关注</h1>
                                 <p class="empty_cue2">看看别的设计师吧</p>
                            </template>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>

        </van-list>
      </div>

      
    <van-overlay :show="showCover"></van-overlay>
    <c-login-coupon ref="cLoginCoupon"></c-login-coupon>
  </div>
</template>

<script>
import { Image, Tab, Tabs, List, Overlay } from "vant";
import CImage from "../../components/CImage";
import CWaterfall from "../../components/CWaterfall.vue";
import CUserCards from "../../components/CUserCards.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { toastLoading, closeToast } from "../../lib/util";
import CLoginCoupon from '../../components/CLoginCoupon';
import { loginCoupon } from '../../lib/mix/login-coupon';
import { loginThird } from "../../lib/mix/login-third";
export default {
    components: {
        [Image.name]: Image,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [Overlay.name]: Overlay,
        [CImage.name]: CImage,
        [CWaterfall.name]: CWaterfall,
        [CUserCards.name]: CUserCards,
        [CLoginCoupon.name]: CLoginCoupon
    },
    mixins: [loginCoupon, loginThird],
    data() {
        return {
            isMe: false,    //是否为个人主页
            pageLoaded: false,  //页面加载完成
            tabLoaded: false,   //tab内容加载完成
            tabActive: 0,   //tab位置
            winWidth: window.innerWidth,
            showCover: false,

            loading: false, //分页加载
            finished: false, //分页全部加载完成

            list: [],
            page: 1,
            totalPage: 0,

            time: null,
        };
    },
    computed: {
        ...mapState({
            userId: (state) => state.me.userId,
            fromRoute: (state) => state.fromRouteName,

            userData: (state) => state.me.meData.user,
            isVip: (state) => state.me.meData.vip_flag,
            isFollow: (state) => state.me.meData.is_follow,

            workNum: (state) => state.me.meData.work_num,
            followNum: (state) => state.me.meData.follow_num,
            fansNum: (state) => state.me.meData.fans_num,
            findNum: (state) => state.me.meData.find_num,

            works: (state) => state.me.works.list,
            worksPages: (state) => state.me.works.total_pages,

            follows: (state) => state.me.follows.list,
            followsPages: (state) => state.me.follows.total_pages,

            finds: (state) => state.me.finds.list,
            findsPages: (state) => state.me.finds.total_pages,

            fans: (state) => state.me.fans.list,
            fansPages: (state) => state.me.fans.total_pages,

            headerNewsMe: state => state.headerNewsMe,
            reloadMe: state => state.reloadMe
        }),
    },
    watch: {
        userId(val) {       //从当前页面切换主页数据刷新
            if (this.fromRoute == "homepage") {

                this.pageLoaded = false;

                // this.loadPage()

                this.checkRouter();

            }
        },
        works(val) {
            if (val.length > 0) {
                this.list = this.list.concat(val);
                this.page++;
            } else {
                this.list = [];
                this.page = 1;
                this.totalPage = 0;
            }
        },
        follows(val) {
            if (val.length > 0) {
                this.list = this.list.concat(val);
                this.page++;
            } else {
                this.list = [];
                this.page = 1;
                this.totalPage = 0;
            }
        },
        finds(val) {
            if (val.length > 0) {
                this.list = this.list.concat(val);
                this.page++;
            } else {
                this.list = [];
                this.page = 1;
                this.totalPage = 0;
            }
        },
        fans(val) {
            if (val.length > 0) {
                this.list = this.list.concat(val);
                this.page++;
            } else {
                this.list = [];
                this.page = 1;
                this.totalPage = 0;
            }
        },
        headerNewsMe(val) {
            if (val == 'save') {    //header给me发送通信，保存当前页面数据，再返还通信
                this.saveHistory().then(() => {
                    this.setHeaderNewsMe('route')
                })
            }
        },
        reloadMe(val) {
            if (val) {
                this.loadPage();
                this.setReloadMe(false)
            }
        }
    },
    methods: {
        ...mapActions({
            requestInfo: "me/requestInfo",
            requestHomepage: "me/requestHomepage",
            requestFollow: "me/requestFollow",
            requestFans: "me/requestFans",
            requestFind: "me/requestFind",
            requestFollowMe: "me/requestFollowMe",
            resumeStoreHistory: "me/resumeStoreHistory",
        }),

        ...mapMutations({
            setWorks: "me/SET_WORKS",
            setFollows: "me/SET_FOLLOWS",
            setFind: "me/SET_FIND",
            setFans: "me/SET_FANS",
            setIsFollow: "me/SET_IS_FOLLOW",
            setHeaderNewsMe: "SET_HEADER_NEWS_ME",
            setReloadMe: "SET_RELOAD_ME"
        }),

        onFollow(num) {
            if (this.$cookies.isKey("user_id")) {
                this.showCover = true;
                this.requestFollowMe({ follow_user_id: this.userId })
                    .then(() => {
                        this.setIsFollow(num);
                        this.showCover = false;
                    })
                    .catch(() => {
                        this.showCover = false;
                    });
            } else {
                this.$router.push({
                    name: "login",
                });
            }
        },

        onHomepageStepPage(data) {
            this.saveHistory().then(() => {
                this.$router.push({
                    name: data.to,
                    params: { id: data.id, enterType: data.to == 'homepage' ? "click" : '' },
                    query: { tab: 0 }
                });
            })
        },

        onReloadProducts() {
            this.loadPage();
        },

        onChangeTab() {
            clearTimeout(this.time);
            this.initList();

            this.$router.replace({
                name: this.$route.name,
                params: this.$route.params,
                query: { tab: this.tabActive }
            })

            // toastLoading();

            this.loadData(this.tabActive).then(() => {
                closeToast();
                this.tabLoaded = true;
            })
        },

        onLoadList() {  //分页加载
            if (this.tabLoaded) {
                this.time = setTimeout(() => {
                    clearTimeout(this.time);

                    if (this.loading) {
                        if (this.page > this.totalPage) {
                            this.finished = true;
                            this.loading = false;
                        } else {
                            this.loadData(this.tabActive)
                        }
                    }
                }, 100);
            }
        },

        initList() {    //初始化列表
            this.list = [];
            this.page = 1;
            this.totalPage = 1;

            this.loading = false;
            this.finished = false;
            this.tabLoaded = false;

            this.setWorks({ list: [], total_pages: 0 });
            this.setFollows({ list: [], total_pages: 0 });
            this.setFans({ list: [], total_pages: 0 });
            this.setFind({ list: [], total_pages: 0 });
        },

        loadData(tab) {     //加载数据
            return new Promise(resolve => {
                this.requestData(tab).then(() => {
                    closeToast();

                    if (tab == 0) this.totalPage = this.worksPages;
                    else if (tab == 1) this.totalPage = this.followsPages;
                    else if (tab == 2) this.totalPage = this.findsPages;
                    else if (tab == 3) this.totalPage = this.fansPages;

                    this.time = setTimeout(() => {
                        clearTimeout(this.time);
                        this.loading = false;
                    }, 300);

                    resolve();
                })
            })
        },

        loadPage() {      //加载页面
            this.initList();

            // 判断是否是个人主页
            let _id = this.$route.params.id;
            let _userId = this.$cookies.get("user_id");
            this.isMe = false;
            if (_userId && _id == _userId) this.isMe = true;

            //url有tab参数，跳转到置顶tab加载对应数据，没有则默认0
            let _tab = this.$route.query.tab;
            this.tabActive = _tab ? parseInt(_tab) : 0;

            // toastLoading();
            this.requestInfo({ user_id: _id }).then(() => {
                this.pageLoaded = true;
                document.title = `${this.userData.nickname}的个人主页_享设计`;

                this.loadData(this.tabActive).then(() => {
                    this.tabLoaded = true;
                })

            });
        },

        checkRouter() {    //判断路由决定页面加载逻辑
            let _enterType = this.$route.params.enterType;
            if (_enterType && _enterType == "click") {      //前进路由重新加载页面数据
                this.loadPage();
            } else {    //返回路由则加载缓存数据，没有缓存数据或刷新浏览器则重新加载页面数据
                if (this.checkHistory() && this.fromRoute) {
                    if (this.fromRoute == 'detail'){
                        this.pageLoaded = true;
                    }else {
                        this.resumeHistory().then(() => {
                        this.$nextTick(() => {
                                setTimeout(() => {
                                    document.documentElement.scrollTop = sessionStorage.getItem('page_scrolltop') //缓存数据加载好后回到之前滚动位置（解决安卓手机）
                                    sessionStorage.removeItem('page_scrolltop')
                                    this.pageLoaded = true;
                                }, 200)
                            })
                        })
                    }
                } else {
                    this.loadPage();
                }
            }
        },

        requestData(tab) {   //tab所对应的接口
            return new Promise(resolve => {
                let _id = this.$route.params.id;

                if (tab == 0) {
                    this.requestHomepage({ user_id: _id, p: this.page }).then(() => {
                        resolve();
                    })
                } else if (tab == 1) {
                    this.requestFollow({ user_id: _id, p: this.page }).then(() => {
                        resolve();
                    })

                } else if (tab == 2) {
                    this.requestFind({ user_id: _id, p: this.page }).then(() => {
                        resolve();
                    })
                } else if (tab == 3) {
                    this.requestFans({ user_id: _id, p: this.page }).then(() => {
                        resolve();
                    })
                }
            })
        },

        saveHistory() {     //缓存主页数据
            return new Promise((resolve) => {
                let _id = this.$route.params.id;
                let _data = {};
                _data.isMe = this.isMe;
                _data.pageLoaded = this.pageLoaded;
                _data.tabLoaded = this.tabLoaded;
                _data.tabActive = this.tabActive;
                _data.loading = this.loading;
                _data.finished = this.finished;
                _data.list = this.list;
                _data.page = this.page;
                _data.totalPage = this.totalPage;
                sessionStorage.setItem(
                    `homepage_${_id}`,
                    JSON.stringify({
                        data: _data,
                        meData: this.$store.state.me.meData,
                    })
                );
                resolve();
            });
        },

        checkHistory() {    //查询缓存
            let _id = this.$route.params.id
            let _data = sessionStorage.getItem(`homepage_${_id}`);
            if (_data) return true;
            else return false;
        },

        resumeHistory() {   //恢复缓存
            return new Promise((resolve) => {
                let _id = this.$route.params.id;
                let _data = JSON.parse(
                    sessionStorage.getItem(`homepage_${_id}`)
                );
                this.isMe = _data.data.isMe;
                this.pageLoaded = false
                this.tabLoaded = _data.data.tabLoaded;
                this.tabActive = _data.data.tabActive;
                this.loading = _data.data.loading;
                this.finished = _data.data.finished;
                this.list = _data.data.list;
                this.page = _data.data.page;
                this.totalPage = _data.data.totalPage;
                this.resumeStoreHistory(_id).then(() => {
                    resolve();
                });
            });
        },
    },
    activated() {
        //从其他页面进入主页加载数据

        this.pageLoaded = false;

        // this.checkRouter();

        let _clickFrom = this.$route.params.clickFrom;
        if (
            (this.fromRoute == "detail" && _clickFrom != "nav") ||
            (this.fromRoute == "homepage" && _clickFrom != "nav") ||
            this.fromRoute == "login" ||
            this.fromRoute == "404"
        ) {
            this.pageLoaded = true;

            if (this.list.length == 0) this.loadPage();
        } else {
            this.loadPage();
        }


    },
};
</script>

<style>
.me{opacity: 0;}
.me.on{opacity: 1;}
#me .van-overlay{background: transparent !important;}
.me-cover{width:100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 100;}
.me-content{padding-top:47px;}
.me-avatar{width:100%; height: 120px;overflow: hidden;}
.me-avatar .avatar{width:375px; height:375px;position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 1; pointer-events: none;filter:blur(20px);}
.me-info{background: #fff; padding-top: 70px;}
.me-info .top{width:108px; height: 108px;position:absolute; left: 0; right: 0; top: -54px; margin: auto; z-index: 10;}
.me-info .avatar{ border-radius: 75px; overflow: hidden; border: 4px solid #FFFFFF; background: #ededed;}
.me-info .vip{ position: absolute; width:28px; height: 28px; left: 0; right: 0; bottom:-14px; margin: auto; z-index: 20;}
.me-info h1{ text-align: center; font-weight: normal; font-size: 20px; color: #2E2E30; box-sizing: border-box; margin: 0 12px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.me-info h2{ text-align: center; font-weight: normal; font-size: 14px; color: #B3B3B3;  margin: 4px 12px 0;box-sizing: border-box;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.me-info .tags_m{display: flex; justify-content: center; align-items: center; margin-top: 10px; padding-bottom: 24px; padding-top: 0; height: auto; background: transparent; opacity: 1;}
.me-info .tag_m{width:28px; height: 24px; margin: 0 6px;}
.me-info .btns{ padding-bottom: 22px;}
.me-info .btn{width:108px; height: 36px; border-radius: 4px; line-height: 36px; text-align: center; font-size:14px; margin: 0 auto;}
.me-info .btn.t1{background: #F85659; color: #FFFFFF; border:1px solid #F85659}
.me-info .btn.t2{background: #fff; color: #2E2E30; border:1px solid #2E2E30}
.me-info .btn.t3{background: #fff; color: #F85659; border:1px solid #F85659}
.me-tab .van-tabs__wrap{height:45px; border-bottom: 1px solid #EDEDED; padding: 0 12px; background: #fff;}
.me-tab .van-tabs__line{width:25%;}
.me-tab .van-tab{padding:0;}
.me-tab .van-tab--active{font-weight:normal;}
.me-tab .van-tab__text{color: #2E2E30; line-height: normal;}
/* .me-tab .van-tab__text-wrapper{display: flex; justify-content: center; align-content: center;} */
.me-tab .van-tab__text-wrapper{bottom: -3px;}
.me-tab .van-tabs__content{overflow: hidden;}
/* .me-tab .van-info{position: relative; top: auto; font-weight: normal; border: none; margin: 0; margin-left: 4px; right: auto; padding: 0; transform: none; border-radius: 0; background: transparent; min-width: auto; color: #B3B3B3; line-height: normal; font-size: 16px;} */
.me-tab .van-info{background-color: transparent; left: 0; top: -18px;  box-sizing: content-box; transform: none; line-height: normal; border-radius: 0; font-weight: normal; min-width: auto; font-size: 14px; color: #B3B3B3; left: 0; margin: auto; border: none; padding:0;}
.me-account .empty{margin: 32px auto 0; }
.me-products .empty{margin: 32px auto 0;}
.me-products .empty_cue,.me-account .empty_cue{ font-size:16px ; color: #2E2E30;  text-align: center; margin-top: 32px; font-weight: normal;}
.me-tab .empty_cue2{text-align: center;font-size: 14px;color: #B3B3B3; margin-top: 8px;}
.me-products{margin-top:12px; padding:0 12px; opacity: 0;}
.me-products.on{opacity: 1;}
.me-products .img{width:170px;overflow: hidden; background: #ededed; margin-bottom: 12px; border-radius: 4px;}
.me-products .vue-waterfall{overflow-y: hidden; height: auto;}
.me-products .img{border-radius: 4px; overflow-y: hidden; margin-bottom: 12px; background: #ededed;}
.me-products .info{padding: 12px; background: #fff; height: 85px;}
.me-products .info h6{font-size: 12px;color: #2E2E30; width:146px; overflow: hidden; text-overflow:ellipsis;white-space:nowrap;}
.me-products .content{margin-top: 10px; display: flex; justify-content:flex-start; align-items: center;}
.me-products .avatar{width:36px; height: 36px; border-radius:50%; overflow-y: hidden; margin-right: 12px;}
.me-products .user{flex:1;}
.me-products .user p{ font-size: 12px; overflow: hidden; text-overflow:ellipsis;white-space:nowrap; width:98px;}
.me-products .user i{font-style: normal;}
.me-products .level{color: #F85659; margin-right: 8px;}
.me-products .level_name{color: #B3B3B3;}
.me-products .name{margin-top: 2px; color: #666666;}
.me-products .line{position: absolute; left: 0; top: -3px; z-index: 1;}
.me-account{padding:12px; opacity: 0;}
.me-account.on{opacity: 1;}

</style>